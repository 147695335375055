import { Box, FormControl, Typography, styled } from '@mui/material';
import {
  StyledOptionItem,
  StyledSelect,
} from 'components/inputs/select/select';
import { CustomSvgIcon } from 'components/svg-icon/svg-icon';
import { ReactComponent as ThreeLines } from 'assets/icons/three-lines.svg';
import { FontSizes } from 'constants/font-sizes';
import { SelectChangeEvent } from '@mui/material/Select';
import { Colors } from 'constants/colors';
import { SeverityIcon } from 'pages/dashboard/components/severity-icon/severity-icon';

interface Props {
  value: string;
  showInformational?: boolean;
  onChange: (e: SelectChangeEvent) => void;
  informationalText?: string;
  dataTestId: string;
  useMedium?: boolean;
}
export const SeverityFilter: React.FC<Props> = ({
  value,
  onChange,
  showInformational,
  informationalText,
  dataTestId,
  useMedium,
}) => {
  return (
    <StyledFormControl>
      <Typography
        variant="h3"
        sx={{ margin: '0', color: Colors.whiteTransparent05 }}
      >
        Severity
      </Typography>
      <StyledSelect
        variant="outlined"
        color="info"
        size="medium"
        value={value}
        //@ts-ignore
        onChange={onChange}
        sx={{ minWidth: '120px', width: 'auto' }}
        data-testid={dataTestId}
      >
        <StyledOptionItem value={'all'}>
          <CustomSvgIcon
            sx={{
              fontSize: FontSizes.fontSize14,
              width: '10px',
              height: '10px',
              marginRight: '6px',
            }}
          >
            <ThreeLines fill={Colors.white} />
          </CustomSvgIcon>
          All
        </StyledOptionItem>
        <StyledOptionItem value={'5'}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <SeverityIcon severityScore={5} iconSize="16px" />
            Critical
          </Box>
        </StyledOptionItem>
        <StyledOptionItem value={'4'}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <SeverityIcon severityScore={4} iconSize="16px" />
            High
          </Box>
        </StyledOptionItem>
        <StyledOptionItem value={'3'}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <SeverityIcon severityScore={3} iconSize="16px" />
            {useMedium ? 'Medium' : 'Moderate'}
          </Box>
        </StyledOptionItem>
        <StyledOptionItem value={'2'}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <SeverityIcon severityScore={2} iconSize="16px" />
            Low
          </Box>
        </StyledOptionItem>
        {!showInformational ? (
          <StyledOptionItem value={'1'}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <SeverityIcon severityScore={1} iconSize="16px" />
              {informationalText || 'Informational'}
            </Box>
          </StyledOptionItem>
        ) : null}
      </StyledSelect>
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)({
  flexDirection: 'row',
  gap: 10,
  alignItems: 'center',
});
