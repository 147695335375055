import { ReactNode } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';

interface Props extends SvgIconProps {
  children: ReactNode;
  iconColor?: string;
  dataTestId?: string;
}

export const CustomSvgIcon = ({
  children,
  viewBox,
  iconColor,
  dataTestId,
  ...rest
}: Props) => {
  return (
    <SvgIcon
      {...rest}
      viewBox={viewBox || '0 0 24 24'}
      sx={{
        color: iconColor || Colors.white,
        fontSize: FontSizes.fontSize12,
        ...rest.sx,
      }}
      data-testid={dataTestId}
    >
      {children}
    </SvgIcon>
  );
};
