import React, { useContext, useEffect } from 'react';
import {
  IDENTITIES_TABLE_NAME_LINK,
  POLICY_LIBRARY_SEVERITY_FILTER,
  SAST_MAIN_TABLE,
  SAST_PAGE_HEADER,
} from '../../constants/test-ids';
import { PageHeader } from '../../components/page-components/page-header/page-header';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  ExtraCellsObject,
  TableComponent,
} from '../../components/table/table-component/table-component';
import { useGetResponseDataWithPagination } from '../../hooks/use-get-response-data-with-pagination';
import { ApiContext } from '../../components/providers/api-provider/api-provider';
import { tableStructure } from './constants';
import { Colors } from '../../constants/colors';
import { FontSizes } from '../../constants/font-sizes';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import { TableCellWithLink } from 'components/table/table-components/table-components';
import { useNavigate } from 'react-router-dom';
import { paths } from 'constants/routes';
import {
  RightTableFiltersContainer,
  TableFiltersContainer,
} from 'components/table/styles';
import { SeverityFilter } from 'pages/alerts/components/severity-filter/severity-filter';
import {
  setApplicableFilters,
  setItemsPerPage,
  setSelectedTable,
} from 'store/modules/selected-column/selected-column.reducer';
import { useDispatch } from 'react-redux';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import { FiltersTableName } from 'store/modules/filters/filters.reducer';

const mapSeverityToText: any = {
  '2': 'low',
  '3': 'medium',
  '4': 'high',
  '5': 'critical',
};

export const SastScan = () => {
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { applicableFilters: tableFilters } = useSelectedColumnSelector();

  const [selectedSeverity, setSelectedSeverity] = React.useState('all');
  const handleSeverityDropdownChange = (event: SelectChangeEvent) => {
    setSelectedSeverity(event.target.value);
    dispatch(
      setApplicableFilters(
        selectedSeverity !== 'all' ? { severity: selectedSeverity } : {},
      ),
    );
  };

  useEffect(() => {
    dispatch(setSelectedTable(FiltersTableName.IAC_FINDINGS_TABLE));
    dispatch(setItemsPerPage(10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useLazyGetSastScanDataQuery =
    apiContext.baseApi?.useLazyGetSastScanDataQuery;

  const [open, setOpen] = React.useState(false);
  const [stateData, setStateData] = React.useState<any>(null);

  const handleOpen = (item: any) => {
    setStateData(item);
    setOpen(true);
  };

  const handleClose = () => {
    setStateData(null);
    setOpen(false);
  };

  const {
    dataList: sastScanList,
    isLoading: isSastDataLoading,
    isError: isSastDataErrored,
    isFetching: isSastDataFetching,
    isNoFindings: isEmptySastData,
  } = useGetResponseDataWithPagination({
    baseApiCallback: useLazyGetSastScanDataQuery,
    itemsPerPage: 10,
    tableFilters,
    severity:
      selectedSeverity === 'all'
        ? undefined
        : mapSeverityToText[selectedSeverity],
  });

  const dialogContent = (item: any) => (
    <>
      {stateData?.message && (
        <Box
          sx={{
            display: 'flex',
            height: '60px',
          }}
        >
          <Typography
            color={Colors.whiteTransparent07}
            fontSize={FontSizes.fontSize16}
            fontWeight={500}
            sx={{ minWidth: '150px' }}
          >
            Message:{' '}
          </Typography>
          <Typography
            color={Colors.white}
            fontSize={FontSizes.fontSize16}
            fontWeight={600}
          >
            {stateData?.message}
          </Typography>
        </Box>
      )}
      {stateData?.branch && (
        <Box
          sx={{
            display: 'flex',
            height: '60px',
          }}
        >
          <Typography
            color={Colors.whiteTransparent07}
            fontSize={FontSizes.fontSize16}
            fontWeight={500}
            sx={{ minWidth: '150px' }}
          >
            Branch:{' '}
          </Typography>
          <Typography
            color={Colors.white}
            fontSize={FontSizes.fontSize16}
            fontWeight={600}
          >
            {stateData?.branch}
          </Typography>
        </Box>
      )}
      {stateData?.start_line && (
        <Box
          sx={{
            display: 'flex',
            height: '60px',
          }}
        >
          <Typography
            color={Colors.whiteTransparent07}
            fontSize={FontSizes.fontSize16}
            fontWeight={500}
            sx={{ minWidth: '150px' }}
          >
            Start Line:{' '}
          </Typography>
          <Typography
            color={Colors.white}
            fontSize={FontSizes.fontSize16}
            fontWeight={600}
          >
            {stateData?.start_line}
          </Typography>
        </Box>
      )}
      {stateData?.end_line && (
        <Box
          sx={{
            display: 'flex',
            height: '60px',
          }}
        >
          <Typography
            color={Colors.whiteTransparent07}
            fontSize={FontSizes.fontSize16}
            fontWeight={500}
            sx={{ minWidth: '150px' }}
          >
            End Line:{' '}
          </Typography>
          <Typography
            color={Colors.white}
            fontSize={FontSizes.fontSize16}
            fontWeight={600}
          >
            {stateData?.end_line}
          </Typography>
        </Box>
      )}
      {stateData?.sha && (
        <Box
          sx={{
            display: 'flex',
            height: '60px',
          }}
        >
          <Typography
            color={Colors.whiteTransparent07}
            fontSize={FontSizes.fontSize16}
            fontWeight={500}
            sx={{ minWidth: '150px' }}
          >
            sha:{' '}
          </Typography>
          <Typography
            color={Colors.white}
            fontSize={FontSizes.fontSize16}
            fontWeight={600}
          >
            {stateData?.sha}
          </Typography>
        </Box>
      )}
      {stateData?.author_email && (
        <Box
          sx={{
            display: 'flex',
            height: '60px',
          }}
        >
          <Typography
            color={Colors.whiteTransparent07}
            fontSize={FontSizes.fontSize16}
            fontWeight={500}
            sx={{ minWidth: '150px' }}
          >
            Author Email:{' '}
          </Typography>
          <Typography
            color={Colors.binge}
            fontSize={FontSizes.fontSize16}
            fontWeight={600}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                `${paths.identityDetails}?user=${stateData?.author_email}${
                  item.source ? '&source=' + item.source : ''
                }`,
              )
            }
          >
            {stateData?.author_email}
          </Typography>
        </Box>
      )}
    </>
  );

  const dialogContentDismissed = (item: any) => (
    <>
      {stateData?.dismissed_by && (
        <Box
          sx={{
            display: 'flex',
            height: '60px',
          }}
        >
          <Typography
            color={Colors.whiteTransparent07}
            fontSize={FontSizes.fontSize16}
            fontWeight={500}
            sx={{ minWidth: '150px' }}
          >
            Dismissed By:{' '}
          </Typography>
          <Typography
            color={Colors.binge}
            fontSize={FontSizes.fontSize16}
            fontWeight={600}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                `${paths.identityDetails}?user=${stateData?.dismissed_by}${
                  item.source ? '&source=' + item.source : ''
                }`,
              )
            }
          >
            {stateData?.dismissed_by}
          </Typography>
        </Box>
      )}
      {stateData?.dismissed_at && (
        <Box
          sx={{
            display: 'flex',
            height: '60px',
          }}
        >
          <Typography
            color={Colors.whiteTransparent07}
            fontSize={FontSizes.fontSize16}
            fontWeight={500}
            sx={{ minWidth: '150px' }}
          >
            Dismissed Date:{' '}
          </Typography>
          <Typography
            color={Colors.white}
            fontSize={FontSizes.fontSize16}
            fontWeight={600}
          >
            {moment(stateData?.dismissed_at).format('MMM DD, YYYY - LT')}
          </Typography>
        </Box>
      )}
      {stateData?.dismissed_reason && (
        <Box
          sx={{
            display: 'flex',
            height: '60px',
          }}
        >
          <Typography
            color={Colors.whiteTransparent07}
            fontSize={FontSizes.fontSize16}
            fontWeight={500}
            sx={{ minWidth: '150px' }}
          >
            Reason:{' '}
          </Typography>
          <Typography
            color={Colors.white}
            fontSize={FontSizes.fontSize16}
            fontWeight={600}
          >
            {stateData?.dismissed_reason}
          </Typography>
        </Box>
      )}
      {stateData?.dismissed_comment && (
        <Box
          sx={{
            display: 'flex',
            height: '60px',
          }}
        >
          <Typography
            color={Colors.whiteTransparent07}
            fontSize={FontSizes.fontSize16}
            fontWeight={500}
            sx={{ minWidth: '150px' }}
          >
            Comment:{' '}
          </Typography>
          <Typography
            color={Colors.white}
            fontSize={FontSizes.fontSize16}
            fontWeight={600}
          >
            {stateData?.dismissed_comment}
          </Typography>
        </Box>
      )}
    </>
  );

  const dialogContentFixed = (item: any) => (
    <>
      {dialogContentDismissed(item)}
      {stateData?.fixed_at && (
        <Box
          sx={{
            display: 'flex',
            height: '60px',
          }}
        >
          <Typography
            color={Colors.whiteTransparent07}
            fontSize={FontSizes.fontSize16}
            fontWeight={500}
            sx={{ minWidth: '150px' }}
          >
            Fixed Date:{' '}
          </Typography>
          <Typography
            color={Colors.white}
            fontSize={FontSizes.fontSize16}
            fontWeight={600}
          >
            {stateData?.fixed_at}
          </Typography>
        </Box>
      )}
    </>
  );

  const RenderStateCell = (item: any) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{ color: Colors.binge, cursor: 'pointer' }}
          onClick={() => handleOpen(item)}
        >
          {item.state.charAt(0).toUpperCase() + item.state.substr(1)}
        </Typography>
        <Dialog
          open={open}
          onClose={handleClose}
          hideBackdrop
          sx={{
            '& .MuiDialog-paper': {
              background: Colors.blackPearl,
            },
          }}
        >
          <DialogContent>
            <Box sx={{ p: 2 }}>
              {dialogContent(item)}
              {stateData?.state === 'dismissed' && dialogContentDismissed(item)}
              {stateData?.state === 'fixed' && dialogContentFixed(item)}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const RenderCreatedAt = (item: any) => {
    return dayjs(item.created_at).format('L LT');
  };

  const RenderUpdatedAt = (item: any) => {
    return dayjs(item.updated_at).format('L LT');
  };

  const RenderAuthorLoginCell = (item: any) => {
    return (
      <>
        {item?.author_email ? (
          <TableCellWithLink
            handleClick={() => {
              handleClose();
              navigate(
                `${paths.identityDetails}?user=${item?.author_email}${
                  item.source ? '&source=' + item.source : ''
                }`,
              );
            }}
            isActive={true}
            itemTitle={item.author_email}
            align="left"
            dataTestId={IDENTITIES_TABLE_NAME_LINK}
          />
        ) : (
          '-'
        )}
      </>
    );
  };

  const SastExtraCell: ExtraCellsObject = {
    state: RenderStateCell,
    author_email: RenderAuthorLoginCell,
    created_at: RenderCreatedAt,
    updated_at: RenderUpdatedAt,
  };

  return (
    <Box>
      <PageHeader title="SAST Scan" dataTestId={SAST_PAGE_HEADER} />
      <TableFiltersContainer>
        {' '}
        <RightTableFiltersContainer>
          <SeverityFilter
            value={selectedSeverity}
            onChange={handleSeverityDropdownChange}
            dataTestId={POLICY_LIBRARY_SEVERITY_FILTER}
            showInformational={true}
            useMedium={true}
          />
        </RightTableFiltersContainer>
      </TableFiltersContainer>
      <TableComponent
        isListLoading={isSastDataLoading}
        isListErrored={isSastDataErrored}
        isEmptyList={isEmptySastData}
        isListFetching={isSastDataFetching}
        dataTestId={SAST_MAIN_TABLE}
        list={sastScanList}
        tableColumns={tableStructure}
        extraCellsRenderObj={SastExtraCell}
      />
    </Box>
  );
};
