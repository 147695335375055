import { Box, styled, Tab, Tabs } from '@mui/material';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const StyledTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  minHeight: '34px',
}));

export const StyledTab = styled(Tab)(() => ({
  color: Colors.white,
  textTransform: 'none',
  fontFamily: fontSecondary,
  fontSize: FontSizes.fontSize14,
  lineHeight: '14px',
  minHeight: '0px',
  padding: '10px 15px',
  '&.Mui-selected': {
    color: Colors.tabSelected,
    borderBottom: `2px solid ${Colors.tabSelected}`,
  },
}));

export const StyledTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: '15px' }}>{children}</Box>}
    </Box>
  );
};
