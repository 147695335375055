import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useKeycloak } from '@react-keycloak/web';

import { Colors } from 'constants/colors';
import logo from 'assets/images/logo.svg';
import namedLogo from 'assets/images/logo-with-name.svg';
import {
  Drawer,
  DrawerHeader,
  ExpandArrowContainer,
  Logo,
  LogoContainer,
  MenuItemWrapper,
  NavItemText,
  NavChildItemText,
  CustomMenuIcon,
  StyledLink,
} from './styles';
import { CustomSvgIcon } from '../svg-icon/svg-icon';
import { NavItemProps, navItems } from './constants';
import { getPath } from 'utils/nav/nav-utils';
import { paths } from 'constants/routes';
import { useDispatch } from 'react-redux';
import {
  FiltersTableName,
  setFiltersForTable,
} from 'store/modules/filters/filters.reducer';
import {
  setApplicableFilters,
  setFilterChips,
  setHighlightColumns,
} from 'store/modules/selected-column/selected-column.reducer';
import Box from '@mui/material/Box';
import { NAVIGATION_DRAWER_PANEL } from 'constants/test-ids';

export const Navigation = (props: any) => {
  const { isDrawerExpanded, connectorsExist, isAdmin } = props;
  const { pathname } = useLocation();
  const currentPath = getPath(pathname);
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const [isChildrenOpen, setIsChildrenOpen] = useState<Record<string, boolean>>(
    {
      actionCenter: false,
      settings: false,
      resources: false,
      reporting: false,
      findings: false,
      recommendations: false,
    },
  );
  // const [isDrawerExpanded, setIsDrawerExpanded] = useState(true);

  const checkRoutePath = (path: string) => {
    if (path === 'entityGraph') {
      dispatch(
        setFiltersForTable({
          tableName: FiltersTableName.ENTITY_GRAPH,
          filters: {},
        }),
      );
    }
    resetFilters();
  };

  const resetFilters = () => {
    dispatch(setApplicableFilters({}));
    dispatch(setFilterChips({}));
    dispatch(setHighlightColumns([]));
  };

  return (
    // <Box sx={{ position: 'relative', zIndex: 1 }}>
    <Drawer
      variant="permanent"
      open={isDrawerExpanded}
      sx={{
        backgroundColor: Colors.blackPearl,
        position: 'relative',
        zIndex: 10,
      }}
      data-testid={NAVIGATION_DRAWER_PANEL}
    >
      <DrawerHeader sx={{ height: '58px' }}>
        <LogoContainer textOpacity={isDrawerExpanded ? 1 : 0}>
          {isDrawerExpanded ? <Logo src={namedLogo} /> : <Logo src={logo} />}
        </LogoContainer>
      </DrawerHeader>
      <MenuList
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 150px)',
          padding: '10px',
        }}
      >
        {(isAdmin
          ? navItems
          : [...navItems].filter((item) => !item.isAdmin)
        ).map((item: NavItemProps) => {
          const isActiveItem = item.activePaths
            ? item.activePaths.includes(currentPath)
            : currentPath === paths[item.path];
          return (
            <MenuItemWrapper
              isBottomItem={item.bottomItem}
              key={item.id}
              data-testid={item.dataTestId}
            >
              <StyledLink
                to={paths[item.path]}
                onClick={() => checkRoutePath(item.path)}
              >
                <CustomMenuIcon
                  onClick={() => {
                    if (item.id === 'signout') {
                      keycloak.logout();
                      return;
                    }
                    // @ts-ignore
                    setIsChildrenOpen((prevState) => {
                      if (Object.keys(isChildrenOpen).includes(item.id)) {
                        return {
                          ...prevState,
                          [item.id]: !prevState[item.id],
                        };
                      }
                      return prevState;
                    });
                  }}
                  isActive={isActiveItem}
                  sx={{
                    height: '41px',
                    backgroundColor: isActiveItem
                      ? Colors.drawerSelection
                      : 'transparent',
                    borderRadius: isChildrenOpen[item.id]
                      ? '5px 5px 0 0'
                      : '5px',
                  }}
                >
                  <ListItemIcon>
                    <CustomSvgIcon
                      iconColor={
                        isActiveItem
                          ? Colors.white
                          : !connectorsExist && !item.noConnectorEnabled
                          ? Colors.whiteTransparent04
                          : Colors.whiteTransparent07
                      }
                      viewBox={'15px'}
                      sx={{
                        marginX: 'auto',
                      }}
                    >
                      {item?.icon()}
                    </CustomSvgIcon>
                  </ListItemIcon>
                  <NavItemText
                    isActive={isActiveItem}
                    opacity={isDrawerExpanded ? 1 : 0}
                    connectorsExist={connectorsExist}
                    noConnectorEnabled={item.noConnectorEnabled}
                  >
                    {item.title}
                  </NavItemText>
                  {item.children && isDrawerExpanded && (
                    <ExpandArrowContainer>
                      {isChildrenOpen[item.id] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ExpandArrowContainer>
                  )}
                </CustomMenuIcon>
              </StyledLink>
              {item.children && (
                <Collapse
                  in={isChildrenOpen[item.id] && isDrawerExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box
                    className="a"
                    display="grid"
                    sx={{
                      border: isActiveItem
                        ? `1px solid ${Colors.drawerSelection}`
                        : 'none',
                      borderRadius: '0px 0px 5px 5px',
                      padding: '15px',
                      gap: '5px',
                    }}
                  >
                    {item.children.map(({ id, path, title, dataTestId }) => {
                      const isActiveChildItem = currentPath === paths[path];
                      return (
                        <Box
                          sx={{
                            '&:nth-last-child(1)': {
                              marginBottom: '0px',
                            },
                          }}
                          data-testid={dataTestId}
                        >
                          <StyledLink
                            to={paths[path]}
                            key={id}
                            onClick={resetFilters}
                          >
                            <CustomMenuIcon
                              isActive={isActiveChildItem}
                              sx={{
                                padding: '15px',
                                height: '41px',
                                borderRadius: '5px',
                              }}
                            >
                              <NavChildItemText
                                connectorsExist={connectorsExist}
                                noConnectorEnabled={item.noConnectorEnabled}
                                isActive={isActiveChildItem}
                              >
                                {title}
                              </NavChildItemText>
                            </CustomMenuIcon>
                          </StyledLink>
                        </Box>
                      );
                    })}
                  </Box>
                </Collapse>
              )}
            </MenuItemWrapper>
          );
        })}
      </MenuList>
    </Drawer>
    // </Box>
  );
};
