import { useDispatch } from 'react-redux';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from '../../../../../../../store/modules/modals/modals.selector';
import React from 'react';
import {
  closeModalByNameAction,
  PlainModals,
} from '../../../../../../../store/modules/modals/modals.reducer';
import { ModalContainer } from '../../../../../../../components/modals/modal-container/modal-container';
import { JsonViewer } from '@textea/json-viewer';
import { Box } from '@mui/material';
import { Colors } from 'constants/colors';

export const DetailsJsonModal = () => {
  const dispatch = useDispatch();
  const metaData = useGetModalMetaData();

  const isRiskReasonEvidenceOpenModal = useIsPlainModalOpenSelector(
    PlainModals.IDENTITIES_DETAILS_DETAILS_JSON_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(
      closeModalByNameAction(PlainModals.IDENTITIES_DETAILS_DETAILS_JSON_MODAL),
    );
  };

  return (
    <ModalContainer
      isOpenModal={isRiskReasonEvidenceOpenModal}
      title={metaData?.title || 'Activity Audit Details'}
      handleCloseModal={handleCloseModal}
      modalWidth="50vw"
      modalHeight="80vh"
    >
      <Box sx={{ marginTop: '30px', overflowY: 'scroll', width: '95%' }}>
        {typeof metaData.details === 'object' ? (
          <JsonViewer
            value={metaData.details}
            theme={'dark'}
            sx={{ 'background-color': Colors.denim }}
          />
        ) : (
          metaData.details
        )}
      </Box>
    </ModalContainer>
  );
};
