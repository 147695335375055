import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

import { CustomSvgIcon } from '../../svg-icon/svg-icon';
import { FontSizes } from 'constants/font-sizes';
import { Colors } from 'constants/colors';
import { fontTertiary } from 'constants/font-family';

interface Props {
  children?: ReactNode;
  iconSize?: string;
  fontSize?: string;
  title: string;
  dataTestId?: string;
}

export const PageHeader = ({
  children,
  title,
  fontSize,
  iconSize,
  dataTestId,
}: Props) => {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
      data-testid={dataTestId}
    >
      {children && (
        <CustomSvgIcon
          sx={{
            fontSize: `${iconSize ? iconSize : FontSizes.headerIcon}`,
          }}
        >
          {children}
        </CustomSvgIcon>
      )}
      <Typography
        variant="h1"
        sx={{
          color: Colors.white,
          fontFamily: fontTertiary,
          margin: '0px',
          fontSize: fontSize ? fontSize : FontSizes.header,
          lineHeight: iconSize ? iconSize : FontSizes.headerIcon,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
