import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { SwitchButton } from '../switch-button/switch-button';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { ToastMessage } from '../toast/toast-message';
import React from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';
import {
  VISIBILITY_HIDE_BUTTON,
  VISIBILITY_NONE_BUTTON,
  VISIBILITY_SHOW_BUTTON,
} from 'constants/test-ids';

interface Props {
  id: string;
  watched: boolean | null | undefined;
  hidden: boolean | null | undefined;
  switchWatched: MutationTrigger<any>;
  switchHidden: MutationTrigger<any>;
  disableTransform?: boolean;
  source?: any;
}

export const VisibilityButton = ({
  id,
  watched,
  hidden,
  switchWatched,
  switchHidden,
  source,
}: Props) => {
  console.log(source);
  const tabDetails = [
    {
      label: 'Hide',
      icon: <VisibilityOff sx={{ width: '16px', height: '16px' }} />,
      dataTestId: VISIBILITY_HIDE_BUTTON,
    },
    {
      label: 'None',
      icon: undefined,
      dataTestId: VISIBILITY_NONE_BUTTON,
    },
    {
      label: 'Show',
      icon: <Visibility sx={{ width: '16px', height: '16px' }} />,
      dataTestId: VISIBILITY_SHOW_BUTTON,
    },
  ];
  const getValue = (): number => {
    if (watched) return 2;
    if (hidden) return 0;
    else return 1;
  };

  const handleVisibility = (event: React.SyntheticEvent, value: number) => {
    if (watched || value === 2) {
      try {
        // @ts-ignore
        source
          ? switchWatched({ id: id!, source: source })
          : switchWatched(id!);
      } catch (error) {
        toast(
          <ToastMessage type="error" title="Your request was unsuccessful" />,
        );
      }
    }
    if (hidden || value === 0) {
      try {
        source ? switchHidden({ id: id!, source: source }) : switchHidden(id!);
      } catch (error) {
        toast(
          <ToastMessage type="error" title="Your request was unsuccessful" />,
        );
      }
    }
  };

  return (
    <Box sx={{ gap: '5px' }}>
      <Typography
        sx={{
          color: Colors.whiteTransparent07,
          fontFamily: fontSecondary,
          fontSize: FontSizes.fontSize12,
          fontWeight: 500,
          mb: '5px',
        }}
      >
        Select
      </Typography>
      <SwitchButton
        value={getValue()}
        onChange={handleVisibility}
        tabDetails={tabDetails}
      />
    </Box>
  );
};
