import React from 'react';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import dayjs from 'dayjs';
import {
  Box,
  Typography,
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  Chip,
} from '@mui/material';
import { Colors } from 'constants/colors';
import { ReactComponent as HighSeverityIcon } from 'assets/icons/severity-high.svg';
import { ReactComponent as MediumSeverityIcon } from 'assets/icons/severity-medium.svg';
import { ReactComponent as LowSeverityIcon } from 'assets/icons/severity-low.svg';
import { ReactComponent as CriticalSeverityIcon } from 'assets/icons/severity-critical.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';

import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
// import { TableCellWithLink } from 'components/table/table-components/table-components';
// import { paths } from 'constants/routes';
import { GenericFirstLevelTabsList } from 'components/generic-tabs-list/first-level-tabs/first-level-tabs';
import { InfoComponent } from 'components/info-component/info-component';
import { contributorInfoStructure } from '../../contributors/constants';
import {
  OPENSOURCE_CONTRIBUTOR_REPUTATION_INFO_COMPONENT,
  OPENSOURCE_CONTRIBUTOR_REPUTATION_TAB,
  OPENSOURCE_CONTRIBUTOR_VULNERABILITIES_TAB,
} from 'constants/test-ids';

const contributorProfileChecklist = [
  { key: 'email', title: 'Email' },
  { key: 'bio', title: 'Bio' },
  { key: 'blog', title: 'Blog' },
  { key: 'company', title: 'Company' },
  { key: 'location', title: 'Location' },
  { key: 'twitter_username', title: 'Twitter' },
];

function sortBySeverity(
  a: {
    packageName: string;
    severity: 'CRITICAL' | 'HIGH' | 'MODERATE' | 'LOW';
  },
  b: {
    packageName: string;
    severity: 'CRITICAL' | 'HIGH' | 'MODERATE' | 'LOW';
  },
) {
  const severityOrder: Record<
    'CRITICAL' | 'HIGH' | 'MODERATE' | 'LOW',
    number
  > = { CRITICAL: 0, HIGH: 1, MODERATE: 2, LOW: 3 };

  const severityA: any = severityOrder[a.severity];
  const severityB: any = severityOrder[b.severity];

  // Compare severity first
  if (severityA < severityB) {
    return -1;
  } else if (severityA > severityB) {
    return 1;
  }

  // If severity is the same, compare by name
  return a.packageName.localeCompare(b.packageName);
}

export const ContributorsReputationModal = () => {
  const dispatch = useDispatch();
  const metaData = useGetModalMetaData();

  //@ts-ignore
  const transparencyScore: any = metaData?.transparencyInfo;

  const ReputationComponent = () => {
    return (
      <Box>
        <Typography sx={{ fontSize: '16px', fontWeight: 600 }} mb={1}>
          Reputation Checklist
        </Typography>
        {contributorProfileChecklist.map(
          (item: { title: string; key: string }, index: number) => (
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="start"
              key={item.key}
              sx={{
                cursor:
                  transparencyScore &&
                  transparencyScore[item.key] !== null &&
                  transparencyScore[item.key]?.length > 0
                    ? 'default'
                    : 'pointer',
                py: 1,
                color:
                  transparencyScore &&
                  !transparencyScore[item.key] !== null &&
                  transparencyScore[item.key]?.length > 0
                    ? Colors.white
                    : Colors.darkDesaturatedCyan,
              }}
            >
              {transparencyScore &&
              transparencyScore[item.key] !== null &&
              transparencyScore[item.key]?.length > 0 ? (
                <CheckCircleOutlineIcon
                  sx={{ fontSize: '18px', mt: '1.5px', color: '#00CA1E' }}
                />
              ) : (
                <CheckCircleOutlineIcon
                  sx={{
                    fontSize: '18px',
                    mt: '1.5px',
                    color: Colors.darkDesaturatedCyan,
                  }}
                />
              )}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                ml={1}
              >
                <Typography
                  variant="body2"
                  fontWeight={550}
                  sx={{
                    textDecoration: 'none',
                    color:
                      transparencyScore &&
                      transparencyScore[item.key] !== null &&
                      transparencyScore[item.key]?.length > 0
                        ? Colors.white
                        : Colors.darkDesaturatedCyan,
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
            </Box>
          ),
        )}
      </Box>
    );
  };

  const VulnerabilitiesComponent = () => {
    return (
      <TableContainer sx={{ marginTop: '25px', maxHeight: '300px' }}>
        <Table
          stickyHeader
          sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell align="left">
                Package Name
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">
                Severity
              </StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transformPackagesWithAdvisories(
              transparencyScore?.vulnerable_packages,
            ).map((item: any, index: number) => (
              <TableRow key={index}>
                <StyledTableBodyCell>{item.packageName}</StyledTableBodyCell>
                <StyledTableBodyCell>
                  {renderSeverityCell(item)}
                </StyledTableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const TabsList = [
    {
      label: 'Reputation',
      dataTestId: OPENSOURCE_CONTRIBUTOR_REPUTATION_TAB,
      component: <ReputationComponent />,
    },
    {
      label: `Vulnerabilities (${transparencyScore?.vulnerable_packages.length})`,
      dataTestId: OPENSOURCE_CONTRIBUTOR_VULNERABILITIES_TAB,
      component: <VulnerabilitiesComponent />,
    },
  ].slice(
    0,
    transparencyScore?.vulnerable_packages &&
      transparencyScore?.vulnerable_packages.length > 0
      ? 2
      : 1,
  );

  const transformPackagesWithAdvisories = (advisories: any[]) => {
    const copyAdvisories = [...advisories];
    return copyAdvisories.sort(sortBySeverity);
  };

  const renderSeverityCell = (item: any) => {
    let chipColor = '';
    let chipBackground = '';
    let chipIcon: JSX.Element;
    switch (item.severity?.toLocaleUpperCase()) {
      case 'CRITICAL':
        chipColor = '#F85455';
        chipBackground = 'rgba(248, 84, 85, 0.2);';
        chipIcon = (
          <CriticalSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'HIGH':
        chipColor = '#F77A63';
        chipBackground = 'rgba(247, 122, 99, 0.2)';
        chipIcon = (
          <HighSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'MODERATE':
        chipColor = '#FF9D2B';
        chipBackground = 'rgba(255, 157, 43, 0.2)';
        chipIcon = (
          <MediumSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'LOW':
        chipColor = '#FDD60D';
        chipBackground = 'rgba(253, 214, 13, 0.2)';
        chipIcon = (
          <LowSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      default:
        chipColor = '#FDD60D';
        chipBackground = 'rgba(253, 214, 13, 0.2)';
        chipIcon = (
          <LowSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
    }
    return (
      <Chip
        size="small"
        sx={{
          backgroundColor: chipBackground,
          color: chipColor,
          border: `1.8px solid ${chipBackground}`,
          px: 1,
        }}
        icon={chipIcon}
        label={item.severity?.toLocaleUpperCase()}
        variant="outlined"
      />
    );
  };

  const isOpenModal = useIsPlainModalOpenSelector(
    PlainModals.CONTRIBUTORS_REPUTATION_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.CONTRIBUTORS_REPUTATION_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isOpenModal}
      title={''}
      alignItems={'left'}
      handleCloseModal={handleCloseModal}
      modalWidth="700px"
      modalHeight="700px"
      backgroundColor={Colors.tiber}
    >
      <InfoComponent
        data={transparencyScore}
        isError={false}
        isLoading={false}
        infoStructure={contributorInfoStructure}
        dataTestId={OPENSOURCE_CONTRIBUTOR_REPUTATION_INFO_COMPONENT}
      />
      <GenericFirstLevelTabsList tabsList={TabsList} />
    </ModalContainer>
  );
};
