import React, { ReactNode, useState } from 'react';
import { Box } from '@mui/material';

import { StyledTab, StyledTabPanel, StyledTabs } from './styles';

export function a11yProps(index: number) {
  return {
    id: `first-tab-${index}`,
    'aria-controls': `first-tabpanel-${index}`,
  };
}

interface Props {
  tabsList: { label: string; component: ReactNode; dataTestId: string }[];
}

export const GenericFirstLevelTabsList = (props: Props) => {
  const { tabsList } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="first-level-tabs"
      >
        {tabsList.map((tab, index) => (
          <StyledTab
            label={tab?.label}
            {...a11yProps(index)}
            data-testid={tab.dataTestId}
          />
        ))}
      </StyledTabs>
      {tabsList.map((tab, index) => (
        <StyledTabPanel value={value} index={index}>
          {tab.component}
        </StyledTabPanel>
      ))}
    </Box>
  );
};
