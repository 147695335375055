import { styled } from '@mui/material';
import DatePicker from 'react-datepicker';

import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';
import { fontSecondary } from 'constants/font-family';

export const DateRangeInputContainer = styled('div')(() => ({
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: Colors.lightTiber,
  borderRadius: '5px',
}));

export const SelectDatesContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '25px',
  border: `1px solid ${Colors.darkCyan}`,
  color: Colors.white,
  gap: '5px',
  padding: '0 10px',
  borderRadius: '4px',
  fontSize: FontSizes.fontSize10,
  cursor: 'pointer',
  width: '130px',
}));

export const DatesPopupContainer = styled('div')(() => ({
  backgroundColor: Colors.lightTiber,
  position: 'absolute',
  zIndex: '11',
  top: '8px',
  right: '122px',
  borderRadius: '10px',
  border: '1px solid #1B4F57',
  padding: '14px 10px',
  width: '284px',

  '& > div': {
    textAlign: 'center',
  },

  '& > div > .react-datepicker': {
    border: 'none',
    width: '100%',

    '& > .react-datepicker__navigation > span:before': {
      color: Colors.whiteTransparent07,
      height: FontSizes.fontSize10,
    },

    '& > .react-datepicker__navigation > span': {},

    '& > .react-datepicker__month-container': {
      backgroundColor: Colors.lightTiber,
      width: '100%',

      '& > .react-datepicker__header': {
        backgroundColor: Colors.lightTiber,
        borderBottom: 'none',
        width: '100%',

        '& > .react-datepicker__current-month': {
          color: Colors.white,
          fontSize: FontSizes.fontSize16,
          height: '36px',
          lineHeight: '20px',
          fontFamily: fontSecondary,
          fontWeight: 600,
          marginBottom: '10px',
        },

        '& > .react-datepicker__day-names > .react-datepicker__day-name': {
          height: '36px',
          color: Colors.whiteTransparent07,
          fontSize: FontSizes.fontSize14,
          fontWeight: 500,
          fontFamily: fontSecondary,
          lineHeight: '20px',
        },
      },

      '& > .react-datepicker__month': {
        '& > .react-datepicker__week': {
          '& > .react-datepicker__day': {
            height: '36px',
            color: Colors.white,
            fontSize: FontSizes.fontSize14,
            borderRadius: '4px',
            padding: '4px',

            '&:hover': {
              border: `1px solid ${Colors.white}`,
              backgroundColor: 'transparent',
            },

            '&.react-datepicker__day--disabled': {
              color: Colors.whiteTransparent07,
            },

            '&.react-datepicker__day--keyboard-selected': {
              backgroundColor: Colors.kikorangiBlue,
              borderColor: Colors.kikorangiBlue,
            },
          },
        },
      },
    },
  },
}));

export const DatesContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: Colors.whiteTransparent07,
}));

export const DateValue = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '115px',
  height: '32px',
  border: `1px solid ${Colors.whiteTransparent07}`,
  color: Colors.whiteTransparent07,
  borderRadius: '5px',
  gap: '5px',
  padding: '5px 10px',
  backgroundColor: Colors.lightTiber,
}));

export const StyledDatePicker = styled(DatePicker)(() => ({
  '& > .react-datepicker': {
    border: 'none',
  },
}));

export const ButtonsContainer = styled('div')(() => ({
  backgroundColor: Colors.lightTiber,
  display: 'flex',
  padding: '15px 0px 0px 0px',
  justifyContent: 'flex-end',
  borderTop: '1px solid #0A3B42',
}));

export const DatePickerContainer = styled('div')(() => ({
  width: '100%',
  gap: '15px',
  marginRight: '10px',
}));
