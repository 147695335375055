import { TableColumnProps } from 'components/table/table-component/table-component';
import { Colors } from 'constants/colors';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'detection',
    title: 'Detection',
    position: 'left',
  },
  {
    id: 'time',
    title: 'Time',
    position: 'left',
    isDate: true,
  },
  {
    id: 'policy',
    title: 'Policy',
    position: 'left',
  },
];

export const mapColorsToSeverity = {
  1: Colors.whiteTransparent07,
  2: Colors.low,
  3: Colors.medium,
  4: Colors.high,
  5: Colors.critical,
};
