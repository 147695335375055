import Plot from 'react-plotly.js';
import {
  FormControl,
  SelectChangeEvent,
  styled,
  Typography,
} from '@mui/material';
import { Colors } from '../../../constants/colors';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../../components/providers/api-provider/api-provider';
import { ContainerWithLoader } from '../../../components/container-with-loader/container-with-loader';
import {
  StyledOptionItem,
  StyledSelect,
} from 'components/inputs/select/select';

export const PieChart = (props: any) => {
  const { id } = props;
  const ApiProvider = useContext(ApiContext);
  const [value, setValue] = useState('users');
  const onChange = (e: SelectChangeEvent) => {
    setValue(e.target.value);
  };

  const [getTeamChart, { data: teamChartData, isLoading, isError }] =
    ApiProvider.baseApi?.useLazyGetTeamChartQuery();

  const [getVulnChart, { data: teamVulnChart }] =
    ApiProvider.baseApi?.useLazyGetTeamVulnChartQuery();

  useEffect(() => {
    getTeamChart(id);
    getVulnChart(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ids = [
    value === 'vulns' ? teamVulnChart?.team : teamChartData?.data.team,
    value === 'repos'
      ? teamChartData?.data.repos.map((repo: any) => {
          return [
            teamChartData?.data.team + '-' + repo.name,
            teamChartData?.data.team + '-' + repo.name + '-overpriv_score',
            teamChartData?.data.team + '-' + repo.name + '-asset_risk_score',
          ];
        })
      : value === 'users'
      ? teamChartData?.data.users.map((user: any) => {
          return [
            teamChartData?.data.team + '-' + user.name,
            teamChartData?.data.team + '-' + user.name + '-overpriv_score',
            teamChartData?.data.team + '-' + user.name + '-asset_risk_score',
          ];
        })
      : teamVulnChart?.repos.map((repo: any) => {
          return [
            teamVulnChart?.team + '-' + repo.repo,
            teamVulnChart?.team + '-' + repo.repo + '-oss',
            teamVulnChart?.team + '-' + repo.repo + '-secret',
            teamVulnChart?.team + '-' + repo.repo + '-sast',
            teamVulnChart?.team + '-' + repo.repo + '-iac',
          ];
        }),
  ];

  const labels = [
    value === 'vulns' ? teamVulnChart?.team : teamChartData?.data.team,
    value === 'repos'
      ? teamChartData?.data.repos.map((repo: any) => {
          return [repo.name, 'Overprivilege Score', 'Asset Risk Score'];
        })
      : value === 'users'
      ? teamChartData?.data.users.map((user: any) => {
          return [user.name, 'Overprivilege Score', 'User Risk Aggregate'];
        })
      : teamVulnChart?.repos.map((repo: any) => {
          return [repo.repo, 'OSS', 'Secrets', 'Sast', 'Iac'];
        }),
  ];

  const parents = [
    '',
    value === 'repos'
      ? teamChartData?.data.repos.map((repo: any) => {
          return [
            teamChartData?.data.team,
            teamChartData?.data.team + '-' + repo.name,
            teamChartData?.data.team + '-' + repo.name,
          ];
        })
      : value === 'users'
      ? teamChartData?.data.users.map((user: any) => {
          return [
            teamChartData?.data.team,
            teamChartData?.data.team + '-' + user.name,
            teamChartData?.data.team + '-' + user.name,
          ];
        })
      : teamVulnChart?.repos.map((repo: any) => {
          return [
            teamVulnChart?.team,
            teamVulnChart?.team + '-' + repo.repo,
            teamVulnChart?.team + '-' + repo.repo,
            teamVulnChart?.team + '-' + repo.repo,
            teamVulnChart?.team + '-' + repo.repo,
          ];
        }),
  ];

  const values = [
    value === 'vulns'
      ? teamVulnChart?.repos.reduce(
          (accumulator: number, currentValue: any) => {
            return accumulator + currentValue.repo_weightage;
          },
          0,
        )
      : teamChartData?.data.team_weightage,
    value === 'repos'
      ? teamChartData?.data.repos.map((repo: any) => {
          return [
            repo.repo_weightage,
            repo.overpriv_weightage,
            repo.asset_risk_weightage,
          ];
        })
      : value === 'users'
      ? teamChartData?.data.users.map((user: any) => {
          return [
            user.user_weightage,
            user.overpriv_weightage,
            user.user_risk_weightage,
          ];
        })
      : teamVulnChart?.repos.map((repo: any) => {
          return [
            repo.repo_weightage,
            repo.oss_weightage,
            repo.secret_weightage,
            repo.sast_weightage,
            repo.iac_weightage,
          ];
        }),
  ];

  const valuesDisplay = [
    value === 'vulns'
      ? teamVulnChart?.team_vulnerabilites
      : Math.round(teamChartData?.data.team_risk_score * 100) / 100,
    value === 'repos'
      ? teamChartData?.data.repos.map((repo: any) => {
          return [
            Math.round(repo.asset_risk_score * 100) / 100,
            Math.round(repo.overpriv_score * 100) / 100,
            Math.round(repo.asset_risk_score * 100) / 100,
          ];
        })
      : value === 'users'
      ? teamChartData?.data.users.map((user: any) => {
          return [
            Math.round(user.user_risks_agg * 100) / 100,
            Math.round(user.overpriv_score * 100) / 100,
            Math.round(user.user_risks_agg * 100) / 100,
          ];
        })
      : teamVulnChart?.repos.map((repo: any) => {
          return [
            repo.repo_vulnerabilites,
            repo.oss,
            repo.secret,
            repo.sast,
            repo.iac,
          ];
        }),
  ];

  const data = [
    {
      type: 'sunburst',
      ids: ids.flat(Infinity),
      labels: labels.flat(Infinity),
      parents: parents.flat(Infinity),
      values: values.flat(Infinity),
      textposition: 'inside',
      insidetextorientation: 'radial',
      hovertext: valuesDisplay.flat(Infinity),
      hoverinfo: 'text',
      hovertemplate: '<b>%{label}</b><br /><i>%{hovertext}</i><extra></extra>',
      branchvalues: 'total',
      marker: {
        colors: ['#366b3f'],
        line: { width: 2 },
      },
    },
  ];
  console.log(data);
  var layout = {
    margin: { l: 0, r: 0, b: 0, t: 0 },
    paper_bgcolor: Colors.blackPearl,
  };

  console.log('TEAMCHART DATA', teamChartData);
  return (
    <ContainerWithLoader isLoading={isLoading} isError={isError}>
      <StyledFormControl>
        <StyledSelect
          variant="outlined"
          color="info"
          size="medium"
          value={value}
          //@ts-ignore
          onChange={onChange}
          sx={{ minWidth: '120px', width: 'auto' }}
        >
          <StyledOptionItem value={'users'}>Users</StyledOptionItem>
          <StyledOptionItem value={'repos'}>Repos</StyledOptionItem>
          <StyledOptionItem
            value={'vulns'}
            disabled={!teamVulnChart?.team_vulnerabilites}
          >
            Vulnerabilities
          </StyledOptionItem>
        </StyledSelect>
      </StyledFormControl>
      {data[0].ids.length !== 1 ? (
        <>
          {/*// @ts-ignore*/}
          <Plot data={data} layout={layout} />
        </>
      ) : (
        <Typography
          sx={{ color: Colors.whiteTransparent04 }}
          variant="h1"
          mt={1}
          p={25}
          textAlign="center"
        >
          No {value.toLocaleUpperCase()} Data for the team
        </Typography>
      )}
    </ContainerWithLoader>
  );
};

const StyledFormControl = styled(FormControl)({
  flexDirection: 'row',
  gap: 10,
  alignItems: 'center',
});
