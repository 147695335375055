import * as React from 'react';
import Box from '@mui/material/Box';
import { Colors } from 'constants/colors';
import { StyledPagination } from '../styles';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { FontSizes } from 'constants/font-sizes';
import { fontSecondary } from 'constants/font-family';
import { KeyboardArrowDown } from '@mui/icons-material';

interface PaginationProps {
  count: number;
  page: number | undefined;
  pageChange: (value: number) => void;
  pageSize: number;
  handlePageSizeChange: (event: any) => void;
}

export const PaginationComponent = ({
  count,
  page,
  pageChange,
  pageSize,
  handlePageSizeChange,
}: PaginationProps) => {
  const pageSizeOptions = [10, 25, 50, 100];
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="40px"
    >
      {pageSize && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            height: '32px',
          }}
        >
          <Typography
            sx={{
              fontSize: FontSizes.fontSize14,
              color: Colors.whiteTransparent07,
              fontWeight: 500,
              fontFamily: fontSecondary,
            }}
          >
            Show
          </Typography>
          <FormControl
            style={{
              minWidth: '0px',
              display: 'flex',
              width: '71px',
            }}
            size="small"
          >
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              sx={{
                width: '71px',
                height: '32px',
                padding: '5px 10px',
                border: `1px solid ${Colors.white}`,
                borderRadius: '3px',
                display: 'flex',
                alignItems: 'center',
                color: Colors.white,
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
              IconComponent={(props) => (
                <KeyboardArrowDown
                  {...props}
                  sx={{
                    color: `${Colors.white} !important`,
                    width: '16px',
                    height: '16px',
                    marginTop: '4px',
                    cursor: 'pointer',
                  }}
                />
              )}
            >
              {pageSizeOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  <Typography sx={{ fontSize: FontSizes.fontSize14 }}>
                    {option}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            sx={{
              fontSize: FontSizes.fontSize14,
              color: Colors.whiteTransparent07,
              fontWeight: 500,
              fontFamily: fontSecondary,
            }}
          >
            Rows per page
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="flex-start" height="40px">
        <StyledPagination
          color="primary"
          count={count}
          page={page}
          onChange={(_, value) => pageChange(value)}
          shape="rounded"
          variant="outlined"
          sx={{
            color: Colors.white,
            '& .MuiPaginationItem-icon': {
              fontSize: '22px',
            },
          }}
        />
      </Box>
    </Box>
  );
};
