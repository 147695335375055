import { Box, Typography } from '@mui/material';
import { ExtraCellsObject } from '../../components/table/table-component/table-component';
import { ReactComponent as InternalIcon } from 'assets/icons/internal.svg';
import { useDispatch } from 'react-redux';
import {
  PlainModals,
  openModalByNameAction,
} from 'store/modules/modals/modals.reducer';

const isValidJSONString = (str: any) => {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const PayloadCell = (data: any) => {
  const dispatch = useDispatch();
  const openDetailsJsonModal = () => {
    dispatch(
      openModalByNameAction({
        type: PlainModals.IDENTITIES_DETAILS_DETAILS_JSON_MODAL,
        metaData: {
          details: isValidJSONString(data?.name)
            ? JSON.parse(data?.name)
            : data?.name,
          title: 'Request Payload',
        },
      }),
    );
  };
  return (
    <Typography onClick={openDetailsJsonModal}>
      <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <InternalIcon style={{ marginLeft: '10px' }} />
      </Box>
    </Typography>
  );
};

export const GraphRenderer: ExtraCellsObject = {
  // graph: renderGraphCell,
  payload: PayloadCell,
};
