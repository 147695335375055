import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  styled,
} from '@mui/material';
import { TableNamesOfFilters } from 'components/table/table-filters-components/filter-renderer/filter-renderer';

import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';

export const FiltersContainer = styled('div')<{ horizontal?: boolean }>(
  ({ horizontal }) => ({
    display: 'flex',
    flexDirection: horizontal ? 'row' : 'column',
    maxHeight: '300px',
    overflow: 'scroll',
    overflowX: 'hidden',
  }),
);

export const FilterContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: Colors.white,
}));

export const FilterTitle = styled('div')(() => ({
  fontSize: FontSizes.fontSize11,
  whiteSpace: 'nowrap',
  border: `1px solid ${Colors.darkCyan}`,
  borderRadius: '4px',
  padding: '4px 6px',
  display: 'flex',
  alignItems: 'center',
  gap: '7px',
  height: '25px',

  '&:hover': {
    borderColor: Colors.white,
  },
}));

export const PopupWrapper = styled('div')<{
  horizontal?: boolean;
  x: number;
  y: number;
  columnName: string;
}>(({ horizontal, x, y, columnName }) => ({
  backgroundColor: Colors.lightTiber,
  width: 'auto',
  minWidth: '381px',
  height: 'auto',
  position: 'absolute',
  top: columnName === TableNamesOfFilters.IDENTITIES_USERS_LIST ? 60 : 45,
  right: x + 20 > window.screen.width - 381 ? 0 : undefined,
  zIndex: '11',
  borderRadius: '10px',
  padding: '20px 15px',
}));

export const FilterSection = styled('div')(() => ({
  // padding: '0 10px 10px',
}));

export const SectionTitle = styled('div')(() => ({
  fontSize: FontSizes.fontSize15,
  fontWeight: 600,
  whiteSpace: 'nowrap',
  margin: '0 0 10px 0',
  textAlign: 'start',
  color: Colors.whiteTransparent07,
}));

export const SectionValues = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '6px',
}));

export const StyledFormControl = styled(FormControl)(() => ({
  paddingLeft: '0',
}));

export const StyledFormControlLabel = styled(FormControlLabel)(
  ({ checked }) => ({
    '& .MuiFormControlLabel-label': {
      fontSize: FontSizes.fontSize14,
      color: checked ? Colors.white : Colors.whiteTransparent07,
    },
  }),
);

export const StyledCheckbox = styled(Checkbox)(() => ({
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundColor: Colors.binge,
      color: Colors.lightTiber,
      borderRadius: '3px',
    },
  },

  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    borderRadius: '3px',
    color: Colors.white,
  },
}));

export const StyledRadio = styled(Radio)(() => ({
  color: Colors.binge,
  padding: '3px 9px',

  '&.Mui-checked': {
    color: Colors.binge,

    '& .MuiSvgIcon-root': {
      color: Colors.binge,
    },
  },

  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    color: Colors.whiteTransparent07,
  },
}));

export const FilterStyledRadio = styled(Radio)(() => ({
  color: Colors.binge,

  '&.Mui-checked': {
    color: Colors.binge,

    '& .MuiSvgIcon-root': {
      color: Colors.binge,
    },
  },

  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    color: Colors.darkCyan,
  },
}));
