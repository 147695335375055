import React, { ReactNode } from 'react';
import { Modal, Box, Typography } from '@mui/material';

import { Colors } from 'constants/colors';
import { StyledButton } from '../../button/button';
import { FontSizes } from '../../../constants/font-sizes';
import { fontPrimary } from 'constants/font-family';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface Props {
  children?: ReactNode;
  title?: string;
  subTitle?: string;
  alignItems?: string;
  handleCloseModal?: () => void;
  handleConfirm?: () => void;
  modalWidth?: string;
  minWidth?: string;
  modalHeight?: string;
  isOpenModal: boolean;
  closeModalTitle?: string;
  confirmModalTitle?: string;
  backgroundColor?: string;
}

export const ModalContainer = ({
  children,
  title,
  subTitle,
  alignItems,
  handleCloseModal,
  handleConfirm,
  isOpenModal,
  closeModalTitle,
  confirmModalTitle,
  modalHeight,
  modalWidth,
  minWidth,
  backgroundColor,
}: Props) => {
  return (
    <Modal onClose={handleCloseModal} open={isOpenModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '15%',
          left: '25%',
          // transform: 'translate(-50%, -50%)',
          width: modalWidth || '500px',
          minWidth: minWidth || '500px',
          boxShadow: 24,
          backgroundColor: backgroundColor || Colors.blackPearl,
          display: 'flex',
          flexDirection: 'column',
          alignItems: alignItems ? alignItems : 'center',
          padding: '20px 15px',
          borderRadius: '15px',
          maxHeight: modalHeight || '90vh',
        }}
      >
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box>
            <Box sx={{ textAlign: 'left', width: '100%' }}>
              {title && (
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: FontSizes.fontSize20,
                    fontWeight: 600,
                    fontFamily: fontPrimary,
                  }}
                >
                  {title}
                </Typography>
              )}
              {subTitle && (
                <Typography
                  sx={{
                    fontSize: FontSizes.fontSize10,
                    color: Colors.whiteTransparent07,
                    fontFamily: fontPrimary,
                  }}
                >
                  {subTitle}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
            {handleCloseModal && <CloseIcon onClick={handleCloseModal} />}
          </Box>
        </Box>
        {children}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: 'auto',
            paddingTop: '15px',
          }}
        >
          {handleConfirm && (
            <StyledButton
              sx={{ marginLeft: 'auto' }}
              onClick={handleConfirm}
              variant="contained"
              color="secondary"
              size="large"
            >
              {confirmModalTitle ? confirmModalTitle : 'Confirm'}
            </StyledButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
