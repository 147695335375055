/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';

import { StyledTab, StyledTabs } from '../../styles';
import { DateRangeInput } from 'components/custom-date-picker/date-range-input/date-range-input';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import React from 'react';
import { StyledButton } from 'components/button/button';
import {
  ButtonsContainer,
  DateValue,
  DatesContainer,
  DatesPopupContainer,
  StyledDatePicker,
  DatePickerContainer,
} from 'components/custom-date-picker/date-range-input/styles';
import dayjs from 'dayjs';
import moment from 'moment';
import {
  DATE_PICKER_BUTTON,
  DATE_PICKER_CUSTOM_PERIOD,
  DATE_PICKER_MENU_ITEM,
} from 'constants/test-ids';

export enum Periods {
  'weeks',
  'months',
  'years',
  'custom',
}

interface Props {
  tabValue: number;
  setTabValue: (tab: number) => void;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  onSubmitHandler: () => void;
  tabsList: string[];
  minDate?: Date | null;
}

export const DatePicker = ({
  tabValue,
  setTabValue,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onSubmitHandler,
  tabsList,
  minDate,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsToggle(false);
    if (tabValue === 3 && !startDate) setTabValue(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isToggle, setIsToggle] = React.useState(false);

  return (
    <Box>
      <IconButton
        id="period-button"
        aria-controls={openMenu ? 'period-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          minWidth: '120px',
          height: '32px',
          borderRadius: '5px',
          border: '1px solid white',
          padding: '5px 10px',
          gap: '5px',
        }}
        data-testid={DATE_PICKER_BUTTON}
      >
        <Typography
          sx={{
            color: Colors.white,
            fontFamily: fontSecondary,
            fontSize: FontSizes.fontSize14,
            lineHeight: '22px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CalendarIcon style={{ marginRight: '5px' }} />
          {tabsList[tabValue]
            ? tabsList[tabValue]
            : startDate && endDate
            ? startDate.getFullYear() === endDate.getFullYear()
              ? startDate.getMonth() === endDate.getMonth()
                ? `${moment(startDate).format('MMM DD')} - ${moment(
                    endDate,
                  ).format('DD, YYYY')}`
                : `${moment(startDate).format('MMM DD')} - ${moment(
                    endDate,
                  ).format('MMM DD, YYYY')}`
              : `${moment(startDate).format('MMM DD, YYYY')} - ${moment(
                  endDate,
                ).format('MMM DD, YYYY')}`
            : 'Custom'}
        </Typography>
      </IconButton>
      <Menu
        id="period-menu"
        MenuListProps={{
          'aria-labelledby': 'period-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        sx={{
          padding: '0px',
          position: 'absolute',
          top: '8px',
          left: '0px',
          '& .MuiList-padding': {
            padding: '0px',
            borderRadius: '10px',
          },
        }}
      >
        <MenuList
          sx={{
            backgroundColor: Colors.lightTiber,
            padding: '0px',
            gap: '10px',
            borderRadius: '10px',
            border: '1px solid #1B4F57',
            width: '119px',
          }}
        >
          <MenuItem onClick={handleClose} disableRipple>
            <StyledButton
              onClick={() => setTabValue(0)}
              variant="text"
              color={'primary'}
              size="small"
              sx={{
                padding: '0px',
                color: `${tabValue === 0 ? '#00D1FF !important' : ''}`,
              }}
              data-testid={DATE_PICKER_MENU_ITEM}
            >
              {tabsList[0]}
            </StyledButton>
          </MenuItem>
          <MenuItem onClick={handleClose} disableRipple>
            <StyledButton
              onClick={() => setTabValue(1)}
              variant="text"
              color={'primary'}
              size="small"
              sx={{ color: `${tabValue === 1 ? '#00D1FF !important' : ''}` }}
              data-testid={DATE_PICKER_MENU_ITEM}
            >
              {tabsList[1]}
            </StyledButton>
          </MenuItem>
          <MenuItem onClick={handleClose} disableRipple>
            <StyledButton
              onClick={() => setTabValue(2)}
              variant="text"
              color={'primary'}
              size="small"
              sx={{ color: `${tabValue === 2 ? '#00D1FF !important' : ''}` }}
              data-testid={DATE_PICKER_MENU_ITEM}
            >
              {tabsList[2]}
            </StyledButton>
          </MenuItem>
          <MenuItem disableRipple>
            <StyledButton
              onClick={() => {
                setTabValue(3);
                setIsToggle(true);
              }}
              variant="text"
              color={'primary'}
              size="small"
              sx={{ color: `${tabValue === 3 ? '#00D1FF !important' : ''}` }}
              startIcon={<KeyboardArrowLeft />}
              data-testid={DATE_PICKER_CUSTOM_PERIOD}
            >
              Custom
            </StyledButton>
          </MenuItem>
        </MenuList>
      </Menu>
      {tabValue === 3 && isToggle && (
        <DateRangeInput
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onSubmitHandler={onSubmitHandler}
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          setTabValue={setTabValue}
        />
      )}
    </Box>
  );
};
