import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
} from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { useActivePipelineActivityWorkflowIdSelector } from 'store/modules/assets/assets.selector';
import { setActivePipelineActivityWorkflowId } from 'store/modules/assets/assets.reducer';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import { useIsPlainModalOpenSelector } from 'store/modules/modals/modals.selector';
import { TableLoader } from 'components/table/table-components/table-components';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { Colors } from 'constants/colors';
import { ErrorHandler } from 'components/error-handler/error-handler';

export const AssetsRunsModal = () => {
  const dispatch = useDispatch();
  const workflowId = useActivePipelineActivityWorkflowIdSelector();

  const isAssetsRunsOpenModal = useIsPlainModalOpenSelector(
    PlainModals.ASSETS_RUNS_MODAL,
  );

  const apiContext = useContext(ApiContext);

  const { data, isLoading, isError, error } =
    apiContext.baseApi?.useGetAssetsPipelineActivityRunsDetailsQuery(
      workflowId,
    );

  const handleCloseModal = () => {
    dispatch(setActivePipelineActivityWorkflowId(null));
    dispatch(closeModalByNameAction(PlainModals.ASSETS_RUNS_MODAL));
  };

  return (
    <ModalContainer
      modalHeight="400px"
      modalWidth="700px"
      title="Group/Team"
      isOpenModal={isAssetsRunsOpenModal}
      handleCloseModal={handleCloseModal}
    >
      <TableContainer sx={{ maxHeight: '750px' }}>
        <Table
          stickyHeader
          sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
          size="small"
        >
          <TableHead sx={{ height: '42px' }}>
            <TableRow>
              <StyledTableHeaderCell>Runs</StyledTableHeaderCell>
              <StyledTableHeaderCell>Pass or Fail</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isError ? (
              <ErrorHandler error={error} />
            ) : !isLoading && data?.length ? (
              data?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&.MuiTableRow-hover:hover': {
                      backgroundColor: Colors.blackPearl,
                    },
                    '&:nth-of-type(odd)': {
                      backgroundColor: Colors.lightTiber,
                    },
                    '&:nth-of-type(even)': {
                      backgroundColor: Colors.tiber,
                    },
                  }}
                >
                  <StyledTableBodyCell align="left">
                    Run {item.number_of_runs}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell align="left">
                    {item.pass_or_fail}
                  </StyledTableBodyCell>
                </TableRow>
              ))
            ) : (
              <TableLoader colCount={2} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalContainer>
  );
};
