import {
  MenuItem,
  MenuItemProps,
  Select,
  SelectProps,
  styled,
} from '@mui/material';
import { FontSizes } from 'constants/font-sizes';
import { Colors } from 'constants/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface StyledSelectProps extends SelectProps {
  variant: 'standard' | 'outlined' | 'filled';
  color: 'error' | 'primary' | 'info' | 'secondary';
  size: 'small' | 'medium';
}

export const StyledSelect = styled((props: StyledSelectProps) => (
  // @ts-ignore
  <Select IconComponent={ExpandMoreIcon} {...props} />
))<{ fontSize?: string; height?: string }>(({ fontSize, height }) => ({
  //   minWidth: '80px',
  height: height ? height : '30px',
  fontSize: '14px',

  '&.MuiInputBase-sizeSmall': {
    height: '25px',
  },

  '&.MuiOutlinedInput-root': {
    '& .MuiSelect-outlined': {
      color: Colors.white,
      padding: '0 6px',
      fontSize: fontSize || FontSizes.fontSize11,
      lineHeight: '25px',
    },

    '& .MuiSelect-icon': {
      color: Colors.white,
      fontSize: FontSizes.fontSize14,
    },

    '&.MuiInputBase-colorSecondary': {
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.whiteTransparent07,
          borderWidth: '1px',
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.darkCyan,
        borderWidth: '1px',
      },

      '&:hover': {
        borderColor: Colors.darkCyan,
        opacity: 0.8,

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.darkCyan,
          opacity: 0.8,
        },
      },
    },

    '&.MuiInputBase-colorInfo': {
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.whiteTransparent05,
          borderWidth: '1px',
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.whiteTransparent05,
        borderWidth: '1px',
      },

      '&:hover': {
        borderColor: Colors.white,
        opacity: 0.8,

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.white,
          opacity: 0.8,
        },
      },
    },

    '&.MuiInputBase-colorPrimary': {
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #ffffff',
          borderColor: Colors.purple,
          borderWidth: '1px',
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.purple,
        borderWidth: '1px',
      },

      '&:hover': {
        borderColor: Colors.purple,
        opacity: 0.8,

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.purple,
          opacity: 0.8,
        },
      },
    },

    '&.MuiInputBase-colorError': {
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.red,
          borderWidth: '1px',
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.red,
        borderWidth: '1px',
      },

      '&:hover': {
        borderColor: Colors.red,
        opacity: 0.8,

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.red,
          opacity: 0.8,
        },
      },
    },
  },

  '&.MuiFilledInput-root': {
    background: 'none',
    '&:hover': {
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
    },
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },

    '& .MuiSelect-filled': {
      minWidth: '120px',
      padding: '8px 10px',
      fontWeight: 500,
      background: Colors.lightTiber,
      border: `1px solid ${Colors.selectionBoxBorder}`,
      borderRadius: '3px',
      color: Colors.white,

      '&.Mui-disabled': {
        border: `1px solid ${Colors.blackPearl}`,
        color: Colors.dropdownDisabledBackground,
      },
    },
    '& .MuiSvgIcon-root': {
      color: Colors.white,
      width: '16px',
      height: '16px',
      top: 'calc(50% - 8px)',
    },
  },

  '&.MuiInput-underline': {
    '& .MuiSelect-standard': {
      color: Colors.white,
      padding: '0',
      fontSize: fontSize || FontSizes.fontSize10,
      lineHeight: '25px',
    },

    '&.MuiSelect-icon': {
      color: Colors.white,
      fontSize: FontSizes.fontSize14,
    },

    // '&.Mui-disabled': {
    //   opacity: 0.4,
    //   color: Colors.white,
    //   webkitTextFillColor: Colors.white,

    //   '&:before': {
    //     opacity: 0.4,
    //   },
    // },

    '&:hover': {
      opacity: 0.8,

      '&:after': {
        borderBottom: 'none',
      },
    },
    '&:after': {
      borderBottom: 'none',
    },

    '&.MuiInputBase-colorError': {
      '&:before': {
        borderBottom: `1px solid ${Colors.red}`,
      },
    },

    '&.MuiInputBase-colorInfo': {
      '&:before': {
        borderBottom: `1px solid ${Colors.white}`,
      },
    },

    '&.MuiInputBase-colorSecondary': {
      '&:before': {
        borderBottom: `1px solid ${Colors.darkCyan}`,
      },
    },

    '&.MuiInputBase-colorPrimary': {
      '&:before': {
        borderBottom: `1px solid ${Colors.purple}`,
      },
    },
  },
}));

export const StyledOptionItem = styled((props: MenuItemProps) => (
  <MenuItem {...props} />
))<{ fontSize?: string }>(({ fontSize }) => ({
  backgroundColor: Colors.blackPearl,
  fontSize: fontSize || FontSizes.fontSize11,
  color: Colors.white,
  padding: '6px 16px',

  '&:hover': {
    backgroundColor: Colors.blackPearl,
    color: Colors.white,
  },

  '&.Mui-selected': {
    backgroundColor: Colors.blackPearl,
    color: Colors.white,

    '&:hover': {
      backgroundColor: Colors.blackPearl,
    },
  },
}));
