import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Colors } from 'constants/colors';
import { fontPrimary } from 'constants/font-family';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    placement={props.placement ? props.placement : 'top'}
    classes={{ popper: className }}
    sx={{
      maxWidth: 'none',
    }}
  />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Colors.lightTiber,
    boxShadow: `0px 4px 4px 0px ${color ? Colors.tooltipBox : '#032227'}`,
    '&::before': {
      boxShadow: `0px 4px 4px 0px ${color ? Colors.tooltipBox : '#032227'}`,
      border: `1px solid ${color ? color : '#215E68'}`,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.lightTiber,
    boxShadow: `10px 4px 10px 4px ${color ? Colors.tooltipBox : '#032227'}`,
    borderRadius: '5px',
    border: `1.5px solid ${color ? color : '#215E68'}`,
    color: Colors.white,
    fontFamily: fontPrimary,
  },
}));
