import React, { ReactElement } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';

interface Props {
  value: number;
  onChange: (_: React.SyntheticEvent, newValue: number) => void;
  tabDetails: {
    label: string;
    icon: undefined | ReactElement;
    dataTestId: string;
  }[];
}

export const SwitchButton = ({ value, onChange, tabDetails }: Props) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      sx={{
        minHeight: '0px',
        '& .MuiTabs-scroller': {
          '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      {tabDetails.map((tab) => {
        return (
          <Tab
            icon={tab.icon}
            iconPosition={'end'}
            label={tab.label}
            data-testid={tab.dataTestId}
            sx={{
              textTransform: 'capitalize',
              minHeight: '0px',
              height: '22px',
              color: Colors.whiteTransparent07,
              padding: '0px 8px',
              border: '1px solid',
              borderColor: Colors.whiteTransparent07,
              marginRight: '10px',
              borderRadius: '3px',
              minWidth: '0px',
              fontSize: FontSizes.fontSize12,
              lineHeight: '22px',
              '&.Mui-selected': {
                color: Colors.white,
                backgroundColor: Colors.kikorangiBlue,
                borderColor: Colors.kikorangiBlue,
              },
            }}
          />
        );
      })}
    </Tabs>
  );
};
